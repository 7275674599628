import {Component, OnInit} from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import {AuthService} from "@pettly/services/auth/auth.service";
import {User} from "@pettly/models/user";
import {ApiError} from "@pettly/services/error/apiError";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {SocialAuthProvider} from "@pettly/services/auth/sociaAuthProvider";
import { NgIf } from "@angular/common";
import {SocialButtonsComponent} from "@pettly/components/account/login/social-buttons/social-buttons.component";

@Component({
    selector: "app-registration-element",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    SocialButtonsComponent,
  ]
})
export class RegistrationComponent implements OnInit {
  showWait = false;
  submitted = false;
  registrationForm: UntypedFormGroup;
  baseUri: string;

  public get controls() {
    return this.registrationForm.controls;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.registrationForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      // phoneNumber: [
      //   "",
      //   [Validators.required, Validators.pattern("^[0-9]{10,10}$")],
      // ],
    });

    this.baseUri = "https://pettly.io/confirm-email";
  }

  async fbLogin(): Promise<void> {
    await this.socialLogin(this.authService.socialAuthProviders.facebook);
  }

  async googleLogin(): Promise<void> {
    await this.socialLogin(this.authService.socialAuthProviders.google);
  }

  private async socialLogin(provider: SocialAuthProvider) {
    try {
      const result = await provider.signIn();
      await this.onSocialLoginCompleted(result.token, provider.providerName);
    } catch (error) {
      alert(JSON.stringify(error))
    }
  }

  public async register(): Promise<void> {
    try {
      this.submitted = true;
      if (this.registrationForm.invalid) return;
      this.showWait = true;
      await this.authService.register(this.parseUserFromControl());
      alert('Please verify your email to continue.')
      await this.router.navigate(["login"]);
    } catch (err) {
      if (err instanceof ApiError) {
        err.errors.forEach(error => this.mapErrorCodeWithControlText(error));
      } else {
        alert(JSON.stringify(err));
      }
    } finally {
      this.showWait = false;
    }
  }

  private mapErrorCodeWithControlText(error): void {
    switch (error.code) {
      case "PasswordRequiresDigit":
      case "PasswordRequiresLower":
        this.controls["password"].setErrors({passwordNotComplex: true});
        break;
      case "PasswordTooShort":
        this.controls["email"].setErrors({passwordTooShort: true});
        break;
      case "DuplicateUserName":
        this.controls["email"].setErrors({duplicateUserName: true});
        break;
      case "DuplicateEmail":
        this.registrationForm.setErrors({duplicateEmail: true});
        break;
      default:
        console.log(JSON.stringify(error));
    }
  }

  private parseUserFromControl(): User {
    const user = new User();
    user.name = this.controls.name.value;
    user.email = this.controls.email.value;
    user.password = this.controls.password.value;
    user.requestUri = this.baseUri;
    return user;
  }

  async onSocialLoginCompleted(token: string, provider: string): Promise<void> {
    try {
      if (!token) return;
      this.submitted = false;
      this.showWait = true;
      await this.authService.onSocialLogin(token, provider);
      await this.router.navigate(["/home"]);
    } catch (err) {
      if (err instanceof ApiError) {
        err.errors.forEach(error => this.mapErrorCodeWithControlText(error));
      } else {
        alert("Login failed" + JSON.stringify(err));
      }
    } finally {
      this.showWait = false;
    }
  }
}
