<div>
  <ul class="nav-tabs">
    <li *ngFor="let tab of tabs" [class.active]="activeTab === tab.value" (click)="activeTab = tab.value">
      <a>{{ tab.label }}</a>
    </li>
  </ul>
  <div [ngSwitch]="activeTab" class="tab-content">
    <div *ngSwitchCase="'profile'" class="box">
      <app-profile-detail [activeProfile]="selectedProfile()"></app-profile-detail>
    </div>
    <ng-container *ngSwitchCase="'timeline'">
      <app-activity-feed [profile]="selectedProfile()"></app-activity-feed>
    </ng-container>
    <div *ngSwitchCase="'friends' " class="box">
      <app-friend-list [activeProfile]="selectedProfile()"></app-friend-list>
    </div>
    <div *ngSwitchCase="'groups'" class="box">Groups</div>
  </div>
</div>
