import {Component, input} from '@angular/core';
import {NgForOf, NgSwitch, NgSwitchCase} from "@angular/common";
import {ProfileDetailComponent} from "@pettly/components/profile/profileDetail/profile-detail.component";
import {ActivityFeedComponent} from "@pettly/components/profile/activity-feed/activityFeed.component";
import {FriendListComponent} from "@pettly/components/profile/friend-list/friend-list.component";
import {PetProfile} from "@pettly/models/petProfile";

@Component({
  selector: 'profile-container-mobile',
  standalone: true,
  imports: [
    NgSwitch,
    NgForOf,
    ProfileDetailComponent,
    ActivityFeedComponent,
    FriendListComponent,
    NgSwitchCase
  ],
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.scss'
})
export class MobileComponent {
  selectedProfile = input.required<PetProfile>();
  public activeTab: string = 'profile';
  public tabs = [
    {value: 'profile', label: 'About'},
    {value: 'timeline', label: 'Posts'},
    {value: 'friends', label: 'Friends(254)'},
    {value: 'groups', label: 'Groups'}
  ];
}
