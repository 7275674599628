import * as i0 from '@angular/core';
import { Pipe, Injectable, InjectionToken, NgModule, Inject } from '@angular/core';
import linkifyStr from 'linkify-string';
import * as linkify from 'linkifyjs';
import { CommonModule } from '@angular/common';
var LinkType;
(function (LinkType) {
  LinkType["URL"] = "url";
  LinkType["HASHTAG"] = "hashtag";
  LinkType["MENTION"] = "mention";
  LinkType["EMAIL"] = "email";
})(LinkType || (LinkType = {}));
class NgxLinkifyjsPipe {
  transform(value, options) {
    return value ? linkifyStr(value, options) : value;
  }
  static ɵfac = function NgxLinkifyjsPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxLinkifyjsPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "linkify",
    type: NgxLinkifyjsPipe,
    pure: true,
    standalone: false
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxLinkifyjsPipe, [{
    type: Pipe,
    args: [{
      name: 'linkify',
      standalone: false
    }]
  }], null, null);
})();
class NgxLinkifyjsService {
  constructor() {}
  /**
   * Convert the passed text as a string to an appropriate url
   *
   * @param text - the string to convert
   * @param options - options to pass it to the linkifyjs library
   */
  linkify(text, options) {
    return linkifyStr(text, options);
  }
  /**
   * Find any links in a given text as a string
   *
   * @param text - the string to find some links
   */
  find(text) {
    return linkify.find(text);
  }
  /**
   * Test if a given value is a link or an array of all links
   *
   * @param value - the value to test
   */
  test(value) {
    if (typeof value === 'string') {
      return linkify.test(value);
    }
    return value.find(v => !linkify.test(v)) === undefined;
  }
  static ɵfac = function NgxLinkifyjsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxLinkifyjsService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NgxLinkifyjsService,
    factory: NgxLinkifyjsService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxLinkifyjsService, [{
    type: Injectable
  }], () => [], null);
})();
const NgxLinkifyjsConfigToken = new InjectionToken('NgxLinkifyjsConfig');
const DEFAULT_CONFIG = {
  enableHash: true,
  enableMention: true
};
class NgxLinkifyjsModule {
  config;
  static forRoot(config = DEFAULT_CONFIG) {
    return {
      ngModule: NgxLinkifyjsModule,
      providers: [NgxLinkifyjsService, {
        provide: NgxLinkifyjsConfigToken,
        useValue: config
      }]
    };
  }
  constructor(config) {
    this.config = config;
    // TODO REMOVE ME
    // if (config.enableHash) {
    //   hashtag(linkify);
    // }
    //
    // if (config.enableMention) {
    //   mention(linkify);
    // }
  }
  static ɵfac = function NgxLinkifyjsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxLinkifyjsModule)(i0.ɵɵinject(NgxLinkifyjsConfigToken));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxLinkifyjsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxLinkifyjsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [NgxLinkifyjsPipe],
      declarations: [NgxLinkifyjsPipe]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NgxLinkifyjsConfigToken]
    }]
  }], null);
})();

/*
 * Public API Surface of ngx-linkifyjs
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_CONFIG, LinkType, NgxLinkifyjsConfigToken, NgxLinkifyjsModule, NgxLinkifyjsPipe, NgxLinkifyjsService };
