<div class="friends">
  <div class="profile" *ngFor="let friend of friends; let i = index" (click)="navigateToProfile(friend.id)">
    <img [src]="friend.profilePic" [alt]="'profile_' + i"/>
    <small>{{ friend.profileName }}</small>
  </div>
</div>
<div class="see-all">
  <button class="btn btn-link">
    See All
    <span><i class="fa fa-angle-right" aria-hidden="true"></i> </span>
  </button>
</div>
