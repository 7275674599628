import {Component, HostListener, Input} from "@angular/core";
import {DeviceInfoService} from "@pettly/services/ui/device-info.service";
import {ClickedOutsideDirective} from "@pettly/libraries/clicked-outside.directive";
import {NgIf} from "@angular/common";

@Component({
    selector: "app-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
    imports: [
        ClickedOutsideDirective,
        NgIf
    ]
})
export class DialogComponent {
  public visible = false;
  public headerPadding = "20px";
  @Input('title') title: string;

  constructor(private deviceInfo: DeviceInfoService) {
    this.setCutoutPadding();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.hidePopup();
    }
  }

  public showHidePopup(): void {
    if (this.visible) this.hidePopup();
    else this.showPopup();
  }

  private hidePopup() {
    this.visible = false;
  }

  private showPopup() {
    this.visible = true;
  }

  private setCutoutPadding() {
    this.deviceInfo.hasNotch().then(value => {
      if (value) {
        this.deviceInfo.getInsetTop().then(inset => {
          this.headerPadding = (inset + 8).toString() + "px";
        })
      }
    })
  }
}
