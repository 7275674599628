import {Component, input} from '@angular/core';
import {ActivityFeedComponent} from "@pettly/components/profile/activity-feed/activityFeed.component";
import {FriendListComponent} from "@pettly/components/profile/friend-list/friend-list.component";
import {ContainerBoxComponent} from "@pettly/components/container-box/container-box.component";
import {ProfileDetailComponent} from "@pettly/components/profile/profileDetail/profile-detail.component";
import {PetProfile} from "@pettly/models/petProfile";

@Component({
    selector: 'profile-container-desktop',
    imports: [
        ActivityFeedComponent,
        FriendListComponent,
        ContainerBoxComponent,
        ProfileDetailComponent
    ],
    templateUrl: './desktop.component.html',
    styleUrl: './desktop.component.scss'
})
export class DesktopComponent {
  selectedProfile = input.required<PetProfile>();

}
