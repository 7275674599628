<div class="page">
  <div class="top-section">
    <h1 class="app-logo"><i class="fa fa-paw"></i> Pettly</h1>
    <p class="text-muted">Sign in to Pettly</p>
    <app-social-buttons [googleLogin]="googleLogin" [fbLogin]="fbLogin"></app-social-buttons>

  </div>
  <span class="divider text-muted mt-4 mb-3">Or</span>

  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div class="form-group">
      <b>Username or Email Address</b>
      <input type="text" class="form-control" placeholder="Username" formControlName="username"/>
      <div *ngIf="(loginFormControls.username.touched || submitted) &&loginFormControls.username.invalid"
           class="text-danger text-left">
        <small *ngIf="loginFormControls.username.errors.required">Username is required.</small>
        <small *ngIf="loginFormControls.username.errors.userDoesnotExist">Username doesn't exist.</small>
        <small *ngIf="loginFormControls.username.errors.emailNotConfirmed">Your email address is not verified. Please
          check your email and click on the verify link to proceed.</small>
      </div>
    </div>
    <div class="form-group">
      <b>Password </b>
      <input type="password" class="form-control" placeholder="Password" formControlName="password"/>
      <div *ngIf="(loginFormControls.password.touched || submitted) &&loginFormControls.password.invalid"
           class="text-danger text-left">
        <small *ngIf="loginFormControls.password.errors.required">Password is required.</small>
        <small *ngIf="loginFormControls.password.errors.passwordDoesntMatch">Username and Password do not match.</small>
        <small *ngIf="loginFormControls.password.errors.minlength">Password must be at least 8 characters.</small>
        <small *ngIf="loginFormControls.username.errors.emailNotConfirmed">Your email address is not verified. Please
          check your email and click on the verify link to proceed.</small>
      </div>
    </div>
    <button class="btn btn-login" type="submit" [disabled]="showWait">
      <i *ngIf="showWait" class="fa fa-paw fa-spin"></i>Login
    </button>
  </form>
  <a routerLink="/forgot-password" class="pull-xs-right">Forgot Password?</a>
  <p>Not a member? <a routerLink="../register">Register</a></p>
</div>
