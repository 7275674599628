<div>
  <div class="item">
    <button><img src="assets/icons/location-pin.svg" alt="location"/></button>
    <h6>Lives with John Nash in Miami, Florida</h6>
  </div>
  <div class="item">
    <button><img src="assets/icons/gender.svg" alt="gender"/></button>
    <span> {{ activeProfile.gender | genderLiteral }}</span>
  </div>
  <div class="item">
    <button><img src="assets/icons/dog.svg" alt="dog"/></button>
    <span>
      {{ activeProfile.breed.name }} ,
      {{ activeProfile.breed.group.name }}</span
    >
  </div>
  <div class="item">
    <button><img src="assets/icons/cake.svg" alt="birthDate"/></button>
    <span> Born on {{ activeProfile.dateOfBirth | date: format }}</span>
  </div>
</div>
