<div class="navbar">
  <a class="navbar-brand" href="/">
    <img src="assets/icons/logo.svg"/>
    <span>Pettly</span>
  </a>
  <navbar-search-element class="search-box"></navbar-search-element>
  <div class="navbar-navs">
    <div class="nav-item" (click)="homeClicked()">
      <img src="assets/icons/home.svg"/>
      <span class="mx-2">Feed</span>
    </div>
    <div class="nav-item" [puiPopup]="friendsPopup">
      <img src="assets/icons/friend.svg"/>
      <span class="mx-2">Friends</span>
    </div>
    <div class="nav-item" [puiPopup]="notificationPopup">
      <img src="assets/icons/bell.svg"/>
      <span class="mx-2">Notification<sup>{{ newNotificationCount() ? newNotificationCount() : '' }}</sup></span>
    </div>
    <div class="nav-item" [puiPopup]="profilePopup">
      <img src="assets/icons/profile.svg"/>
      <span class="mx-2">Profile<sup>{{ otherPetNotificationCount() ? otherPetNotificationCount() : '' }}</sup></span>
      <i class="fas fa-chevron-down"></i>
    </div>
  </div>
</div>
<ng-template #profilePopup>
  <div class="profile-popup">
    <div class="item" [routerLink]="'/profile'">
      <button><img src="assets/icons/profile1.svg" alt="profiles"/></button>
      <h6 class="m-0">Profiles</h6>
    </div>
    <profile-list-selector [profiles]="profiles()" [selectedProfile]="activeProfile()"></profile-list-selector>
    <div class="item clickable" [routerLink]="['/profile']">
      <button><img src="assets/icons/dog.svg" alt="view_profile"/></button>
      <h6 class="m-0">View Profile</h6>
    </div>
    <div class="item clickable" (click)="addProfile.showHidePopup()">
      <button><img src="assets/icons/add.svg" alt="add_pet"/></button>
      <h6 class="m-0">Add Pet</h6>
    </div>
    <div class="item clickable" (click)="toggleLightMode()">
      <button><img src="assets/icons/{{theme==='dark'?'sun.svg':'moon.svg'}}" alt="theme"/></button>
      <h6 class="m-0">{{ theme === 'dark' ? 'Light' : 'Dark' }} mode</h6>
    </div>
    <div class="item">
      <button><img src="assets/icons/setting.svg" alt="settings"/></button>
      <h6 class="m-0">Settings</h6>
    </div>
    <div class="item clickable" (click)="logoutClicked()">
      <button><img src="assets/icons/logout.svg" alt="logout"/></button>
      <h6 class="m-0">Logout</h6>
    </div>
  </div>
</ng-template>
<ng-template #notificationPopup>
  <navbar-notification-element [activeProfile]="activeProfile()"></navbar-notification-element>
</ng-template>
<ng-template #friendsPopup>
  <navbar-friend-request [activeProfile]="activeProfile()"></navbar-friend-request>
</ng-template>
<div class="mobile-nav" [style.padding-top]="navbarPadding">
  <div *ngIf="router.url === '/'">
    <a class="navbar-brand" href="/">
      <img src="assets/icons/logo.svg" width="30" height="30"/>
      <span>Pettly</span>
    </a>
  </div>
  <div
    class="d-flex align-items-center" *ngIf="router.url.startsWith('/profile')" style="color: #5e6177;">
    <i class="fas fa-chevron-left" (click)="backClicked()"></i>
    <h5 class="ml-2 mb-0">
      {{ "Profile" | titlecase }}
    </h5>
  </div>
  <navbar-search-element style="width: 100%" *ngIf="router.url == '/search'"></navbar-search-element>
  <div *ngIf="router.url === '/'" class="add-post" (click)="postPopup.showHidePopup()">
    <img src="assets/icons/add-post.svg" alt="Add Post"/>
  </div>
  <i *ngIf="router.url === '/profile'" (click)="openProfileSettings()">
    <img src="assets/icons/setting.svg" alt="settings"/>
  </i>
</div>

<app-dialog [title]="'Post'" class="add-post-popup" #postPopup>
  <createPost-element [profileId]="activeProfile()?.id"></createPost-element>
</app-dialog>
<app-dialog [title]="'Add Pet'" #addProfile>
  <app-createprofile></app-createprofile>
</app-dialog>
