import {Component, OnInit, Signal} from "@angular/core";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {PetProfile} from "@pettly/models/petProfile";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";

@Component({
    selector: "footer-element",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    imports: [RouterLinkActive, RouterLink]
})
export class FooterComponent {
  public constructor(private profileStore: PetProfileStorageService, public router: Router, private sharedUiService: SharedUiService) {
  }

  public get profile(): Signal<PetProfile> {
    return this.profileStore.activeProfile;
  }

  public async homeClicked(): Promise<void> {
    if (this.router.url === "/") {
      this.sharedUiService.refreshTimeLine.next();
    }
  }
}
