import {Injectable} from '@angular/core';
import {SocialAuthProvider, SocialLoginResult} from "@pettly/services/auth/sociaAuthProvider";
import {SocialLogin} from '@capgo/capacitor-social-login';
import {GoogleLoginResponseOnline} from "@capgo/capacitor-social-login/dist/esm/definitions";

@Injectable({
  providedIn: 'root'
})
export class GoogleService extends SocialAuthProvider {
  readonly providerName = "GOOGLE";

  protected async initializeImpl() {
    await SocialLogin.initialize({
      google: {
        webClientId: "186966789478-ingfmdlc1jo11n0g2903p75jcro18igm.apps.googleusercontent.com"
      }
    });
  }

  async signIn(): Promise<SocialLoginResult> {
    const res = await SocialLogin.login({
      provider: 'google',
      options: {
        scopes: ['email', 'profile']
      }
    });

    return {token: (res.result as GoogleLoginResponseOnline).accessToken.token};
  }

  async signOut(): Promise<void> {
    return SocialLogin.logout({provider: 'google'});
  }
}
