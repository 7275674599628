<div class="page">
  <div class="sidebar sidebar-left ">
    <userDetail-element [activeProfile]="activeProfile()" [profiles]="profiles()"></userDetail-element>
  </div>
  <div class="middle">
    <app-timeline-element [activeProfile]="activeProfile()"></app-timeline-element>
  </div>
  <div class="sidebar sidebar-right">
    <alerts-element></alerts-element>
  </div>
</div>
