import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var AndroidNotch = /** @class */function (_super) {
  __extends(AndroidNotch, _super);
  function AndroidNotch() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  AndroidNotch.prototype.hasCutout = function () {
    return cordova(this, "hasCutout", {}, arguments);
  };
  AndroidNotch.prototype.getInsetTop = function () {
    return cordova(this, "getInsetTop", {}, arguments);
  };
  AndroidNotch.prototype.getInsetRight = function () {
    return cordova(this, "getInsetRight", {}, arguments);
  };
  AndroidNotch.prototype.getInsetBottom = function () {
    return cordova(this, "getInsetBottom", {}, arguments);
  };
  AndroidNotch.prototype.getInsetLeft = function () {
    return cordova(this, "getInsetLeft", {}, arguments);
  };
  AndroidNotch.ɵfac = /* @__PURE__ */(() => {
    let ɵAndroidNotch_BaseFactory;
    return function AndroidNotch_Factory(__ngFactoryType__) {
      return (ɵAndroidNotch_BaseFactory || (ɵAndroidNotch_BaseFactory = i0.ɵɵgetInheritedFactory(AndroidNotch)))(__ngFactoryType__ || AndroidNotch);
    };
  })();
  AndroidNotch.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AndroidNotch,
    factory: AndroidNotch.ɵfac
  });
  AndroidNotch.pluginName = "AndroidNotch";
  AndroidNotch.plugin = "cordova-plugin-android-notch";
  AndroidNotch.pluginRef = "AndroidNotch";
  AndroidNotch.repo = "https://github.com/tobspr/cordova-plugin-android-notch.git";
  AndroidNotch.platforms = ["Android"];
  AndroidNotch = __decorate([], AndroidNotch);
  return AndroidNotch;
}(AwesomeCordovaNativePlugin);
export { AndroidNotch };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AndroidNotch, [{
    type: Injectable
  }], null, {
    hasCutout: [],
    getInsetTop: [],
    getInsetRight: [],
    getInsetBottom: [],
    getInsetLeft: []
  });
})();
