<div class="alerts">
<div class="box">
  <div class="box-heading">
    <span>Trending Now</span>
  </div>
  <div *ngFor="let post of trendings; let i = index">
    <div class="item">
      <img
        class="rounded-circle mr-2"
        [src]="post.profile"
        alt="profile"
        width="40"
        height="40"
      />
      <div class="content">
        <div class="d-flex justify-content-between">
          <h6 class="m-0">{{ post.name }}</h6>
          <p class="m-0" style="color: #a5abbe;">{{ post.date }}</p>
        </div>
        <span>added a new photo</span>
        <div class="d-flex">
          <img
            [src]="image.url"
            alt="'Image_'+image.id"
            *ngFor="let image of post.images | slice: 0:2"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="box">
  <div class="box-heading">
    <span>Pets You May Know</span>
  </div>
  <div *ngFor="let profile of profiles; let i = index">
    <div class="profiles">
      <div class="d-flex align-items-center">
        <img [src]="profile.img" alt="profile.name" />
        <div class="ml-2">
          <h5 class="m-0">{{ profile.name }}</h5>
          <small>{{ profile.breedName }}</small>
        </div>
      </div>
      <div>
        <button class="btn btn-outline-primary">Follow</button>
      </div>
    </div>
  </div>
</div>
</div>
