import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {DeviceInfoService} from "@pettly/services/ui/device-info.service";
import {RouterOutlet} from '@angular/router';

@Component({
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  animations: [
    trigger('flip', [
      transition('* => *', [
        style({position: 'relative'}),
        group([
          query(':self', [
            style({transform: 'rotateY(0deg)',}),
            animate('250ms ease-in', style({transform: 'rotateY(90deg)'})),
            style({transform: 'rotateY(270deg)',}),
            animate('250ms ease-in', style({transform: 'rotateY(360deg)'}))

          ], {optional: true}),
          query(':leave', [
            animate('0ms 249ms', style({display: 'none', transform: 'none'}))
          ], {optional: true}),
          query(':enter', [
            style({display: 'none'}),
            animate('250ms 250ms ease-out', style({display: 'block'})),
          ], {optional: true}),
        ]),
      ]),
    ])
  ],
  standalone: true,
  imports: [RouterOutlet]
})
export class AccountComponent implements OnInit {
  public accountPadding = "1em";

  constructor(private title: Title,
              private deviceInfo: DeviceInfoService
  ) {
    this.title.setTitle('Pettly: Introduce yourself to pets from all over the world');
  }

  async ngOnInit(): Promise<void> {
    await this.setCutoutPadding()
  }

  private async setCutoutPadding() {
    if (!await this.deviceInfo.hasNotch()) return;
    this.accountPadding = (await this.deviceInfo.getInsetTop() + 8).toString() + "px";
  }
}


