<div class="mobile-footer">
  <div class="icon mr-4" routerLinkActive="active" (click)="homeClicked()" routerLink="/"
       [routerLinkActiveOptions]="{exact:true}">
    <img src="assets/icons/home.svg" alt="Home"/>
  </div>
  <div class="icon" routerLink="/search" routerLinkActive="active">
    <img src="assets/icons/search.svg" alt="Search"/>
  </div>
  <div class="icon" routerLink="/notification" routerLinkActive="active">
    <img src="assets/icons/bell.svg" alt="Notification"/>
  </div>
  <div class="icon" routerLink="/profile" routerLinkActive="active">
    <img class="rounded-circle" [src]="profile().profilePictureLocation" alt="{{profile().name}}}"/>
  </div>
</div>
