<div class="box">
  <div class="cover-image">
    <img
      class="cover-pic"
      src="assets/images/Profile/profile-cover.jpg"
      alt="Profile image"
    />
    <img
      class="profile-pic"
      [src]="selectedProfile().profilePictureLocation"
      [fallbackSrc]="fallbackProfilePicture"
      alt="Profile image"
    />
  </div>
  <div class="cover-details">
    <div class="profile-details">
      <div>
        <h4>{{ selectedProfile().name }}</h4>
        <p>{{ selectedProfile().breed.name }}</p>
      </div>
      <div style="display: flex; align-items: center;">
        <div class="profile-box"><b>245</b><small>Friends</small></div>
        <div class="profile-box"><b>45</b><small>Posts</small></div>
      </div>
    </div>
    <div class="profile-btn">
      <switch-profile class="desk" [selectedProfile]="selectedProfile()" [profiles]="profiles()"></switch-profile>
      <button *ngIf="isOwnProfile" class="mob btn btn-outline-primary" (click)="popup.showHidePopup()">
        Switch Profile
      </button>
      <button *ngIf="isOwnProfile" class="btn btn-outline-primary" (click)="editProfile.showHidePopup()">
        Edit Profile
      </button>
      <button *ngIf="!isOwnProfile" class="btn btn-outline-primary">
        Follow
      </button>
    </div>
  </div>
</div>

<app-dialog [title]="'Edit Profile'" #editProfile>
  <edit-profile></edit-profile>
</app-dialog>
<app-dialog class="profile-popup" title="Switch Profile" #popup>
  <profile-list-selector class="content" [selectedProfile]="selectedProfile()"
                         [profiles]="profiles()"></profile-list-selector>
  <button class="btn btn-primary profile-btn" type="button">Add New Account</button>
</app-dialog>
