import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Constants} from "@pettly/shared/constants";
import {PetProfile} from "@pettly/models/petProfile";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import { NgFor } from "@angular/common";

@Component({
    selector: "app-friend-list",
    templateUrl: "./friend-list.component.html",
    styleUrls: ["./friend-list.component.scss"],
    standalone: true,
    imports: [NgFor],
})
export class FriendListComponent implements OnChanges {
  public friends: {
    id: string;
    profilePic: string;
    profileName: string;
  }[] = [
    {
      id: "1",
      profilePic: "assets/images/Friends/guy-1.jpg",
      profileName: "John Doe",
    },
    {
      id: "2",
      profilePic: "assets/images/Friends/woman-1.jpg",
      profileName: "John Doe",
    },
    {
      id: "3",
      profilePic: "assets/images/Friends/guy-2.jpg",
      profileName: "John Doe",
    },
    {
      id: "4",
      profilePic: "assets/images/Friends/woman-2.jpg",
      profileName: "John Doe",
    },
    {
      id: "5",
      profilePic: "assets/images/Friends/guy-4.jpg",
      profileName: "John Doe",
    },
    {
      id: "6",
      profilePic: "assets/images/Friends/guy-3.jpg",
      profileName: "John Doe",
    },
    {
      id: "7",
      profilePic: "assets/images/Friends/woman-3.jpg",
      profileName: "John Doe",
    },
    {
      id: "8",
      profilePic: "assets/images/Friends/guy-5.jpg",
      profileName: "John Doe",
    },
  ];

  public readonly DEFAULT_PROFILE_PICTURE = Constants.DEFAULT_PROFILE_PICTURE;
  @Input() activeProfile: PetProfile;
  public loading = true;

  constructor(private petCatalogService: PetCatalogService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes['activeProfile'].currentValue?.id) return;
    const friendships = await this.petCatalogService.getFriends(this.activeProfile.id);
    this.friends = friendships.map(friendship => {
      return {
        id: friendship.friend.id,
        profilePic: friendship.friend.profilePicture?.location ?? this.DEFAULT_PROFILE_PICTURE,
        profileName: friendship.friend.name
      }
    });
    this.loading = false;
  }
}
