import {Component, EventEmitter, HostListener, Output, ViewEncapsulation} from '@angular/core';
import { ClickedOutsideDirective } from '@pettly/libraries/clicked-outside.directive';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [ClickedOutsideDirective]
})
export class PopupComponent {
  @Output()
  closed = new EventEmitter<void>();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  public close() {
    this.closed.next();
  }
}
