import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-resetpasswordconfirmation-element',
    templateUrl: './resetpasswordconfirmation.component.html',
    styleUrls: ['./resetpasswordconfirmation.component.scss'],
    standalone: true
})

export class ResetPasswordConfirmationComponent implements OnInit {
    ngOnInit(): void{
        console.log('Log');
    }
}

