import {Component, Input} from '@angular/core';
import {PetProfile, Gender} from '@pettly/models/petProfile';
import {Constants} from '@pettly/shared/constants';
import { GenderPipe } from '@pettly/libraries/gender-pipe';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-profile-detail',
    templateUrl: 'profile-detail.component.html',
    styleUrls: ['profile-detail.component.scss'],
    standalone: true,
    imports: [DatePipe, GenderPipe]
})
export class ProfileDetailComponent {
  format: string;
  @Input() activeProfile: PetProfile;

  constructor() {
    this.format = Constants.DateFormat;
  }

  get gender(): Gender {
    return PetProfile.formattedGender(this.activeProfile.gender);
  }
}
