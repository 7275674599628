<div class="post">
  <div class="title-box">
    <div class="meta">
      <img [fallbackSrc]="fallBackProfilePicture" [src]="post.author.profilePictureUri"/>
      <div class="title">
        <h4>{{ post.author.name }}</h4>
        <h6 class="text-muted">Shared publicly - {{ post.createdAt | retrospective }}</h6>
      </div>
    </div>
    <i class="fa fa-ellipsis-h menu-icon text-muted" style="cursor: pointer" (click)="menuClicked.next(post)"></i>
  </div>
  <div class="content-box">
    <div [ngSwitch]="previewItem" class="media-box">
      <div
        *ngSwitchCase="PreviewFromTypes.Link"
        [innerHTML]="oEmbedContent.html | safeHtml"
        class="link-preview-container"
      ></div>
      <div *ngSwitchCase="PreviewFromTypes.Image">
        <div [ngStyle]="{'grid-template-columns':post.mediaUris.length === 1 ? '1fr' : '1.25fr 1fr'}"
             class="image-container">
          <img
            *ngFor="let image of post.mediaUris | slice: 0:3; let i = index"
            [alt]="'Image_' + i"
            [class]="'image_' + i"
            [src]="image"
          />
        </div>
      </div>
    </div>
    <div *ngIf="post.content" class="post-text" #postContent>
      <span style="overflow-wrap: break-word" bind-innerHTML="post.content|linkify:{format: formatLink}"></span>
    </div>
  </div>
  <div class="interaction-box">
    <div class="interaction-icon-container">
      <img (click)="sendReaction()" [class.active]="hasMyReaction" alt="paw"
           class="interaction-icon paw-icon-adjust" src="assets/icons/paw.svg"/>
      <span>{{ post.comments.length | countPipe }}</span>
    </div>
    <div class="interaction-icon-container">
      <img (click)="mobileComment?popup.showHidePopup():toggleCommentBox()" alt="comment" class="interaction-icon"
           src="assets/icons/comment.svg"/>
      <span>{{ post.comments.length | countPipe }}</span>
    </div>
    <div class="interaction-icon-container">
      <img alt="share" class="interaction-icon" src="assets/icons/share.svg"/>
    </div>
  </div>
  <div class="comment-box" *ngIf="commentBoxVisible">
    <comment-element [comments]="post.comments" [activeProfile]="activeProfile"
                     [postId]="post.id"></comment-element>
  </div>
</div>

<app-dialog #popup class="comment-popup" title="Comments">
  <div class="comment-box-popup">
    <comment-element [comments]="post.comments" [activeProfile]="activeProfile"
                     [postId]="post.id" class="comment-box"></comment-element>
  </div>
</app-dialog>
