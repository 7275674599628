import {Component, Input} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {
  ProfileListSelectorComponent
} from "@pettly/components/profile/profile-list-selector/profile-list-selector.component";
import {PopOverTargetDirective} from "@pettly/libraries/pop-over-target.directive";

@Component({
    selector: 'switch-profile',
    templateUrl: './switch-profile.component.html',
    imports: [
        ProfileListSelectorComponent,
        PopOverTargetDirective
    ],
    styleUrls: ['./switch-profile.component.scss']
})
export class SwitchProfileComponent {
  @Input() public selectedProfile: PetProfile;
  @Input() public profiles: PetProfile[];

  constructor() {
  }
}
