import {Component, Input} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";
import { NgFor, NgClass } from "@angular/common";
import { SwitchProfileComponent } from "../../profile/switch-profile/switch-profile.component";
import { RouterLink } from "@angular/router";

@Component({
    selector: "userDetail-element",
    templateUrl: "./userDetail.component.html",
    styleUrls: ["./userDetail.component.scss"],
    standalone: true,
    imports: [
        RouterLink,
        SwitchProfileComponent,
        NgFor,
        NgClass,
    ],
})
export class UserDetailComponent {
  @Input() activeProfile: PetProfile;
  @Input() profiles: PetProfile[];
}
