import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Comment, PostService} from "@pettly/services/post/post.service";
import {Constants} from "@pettly/shared/constants";
import {PetProfile} from "@pettly/models/petProfile";
import { CountPipe } from '@pettly/libraries/count-pipe';
import { ShortRetrospectiveDateFormatPipe } from '@pettly/libraries/date-short-pipe';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { FormsModule } from '@angular/forms';
import { ImageFallbackDirective } from '@pettly/libraries/image-fallback.directive';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'comment-element',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    standalone: true,
    imports: [NgFor, ImageFallbackDirective, FormsModule, NgIf, PickerComponent, ShortRetrospectiveDateFormatPipe, CountPipe]
})
export class CommentComponent {
  @Input() comments: Array<Comment>;
  @Input() postId: string;
  @Input() activeProfile: PetProfile;
  @ViewChild("content") commentBoxContent: ElementRef;

  public fallBackProfilePicture = Constants.DEFAULT_PROFILE_PICTURE;
  public commentContent = "";
  public showEmojiPicker = false;

  constructor(private postService: PostService) {
  }

  async sendReactionOnComment(comment: Comment): Promise<void> {
    const data = await this.postService.createReactionOnComment(
      0,
      this.postId,
      comment.id,
      this.activeProfile.id
    );
    comment.reactions.push(data);
    //this.hasMyReaction = true;
  }


  public async sendComment(): Promise<void> {
    try {
      const data = await this.postService.createComment(
        this.commentContent,
        this.activeProfile.id,
        this.postId
      );
      this.commentContent = "";
      this.comments.push(data);
    } catch {
      alert("failed to create comment");
    }
  }

  hasMyReaction(comment: Comment): boolean {
    return comment.author.id == this.activeProfile.id;
  }

  public addEmoji(event): void {
    const {commentContent} = this;
    console.log(`${event.emoji.native}`);
    this.commentContent = `${commentContent}${event.emoji.native}`;
    // this.showEmojiPicker = false;
  }

  public onFocus(): void {
    console.log("focus");
    this.showEmojiPicker = false;
  }

  public toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
    if (!this.showEmojiPicker) {
      this.commentBoxContent.nativeElement.focus();
    }
  }
}
