import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Constants} from "@pettly/shared/constants";
import {PetProfile} from "@pettly/models/petProfile";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {NgFor} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: "app-friend-list",
  templateUrl: "./friend-list.component.html",
  styleUrls: ["./friend-list.component.scss"],
  imports: [NgFor]
})
export class FriendListComponent implements OnChanges {
  public friends: {
    id: string;
    profilePic: string;
    profileName: string;
  }[];

  public readonly DEFAULT_PROFILE_PICTURE = Constants.DEFAULT_PROFILE_PICTURE;
  @Input() activeProfile: PetProfile;
  public loading = true;

  constructor(private petCatalogService: PetCatalogService, private router: Router) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes['activeProfile'].currentValue?.id) return;
    const friendships = await this.petCatalogService.getFriends(this.activeProfile.id);
    this.friends = friendships.map(friendship => {
      return {
        id: friendship.friend.id,
        profilePic: friendship.friend.profilePicture?.location ?? this.DEFAULT_PROFILE_PICTURE,
        profileName: friendship.friend.name
      }
    });
    this.loading = false;
  }

  async navigateToProfile(id: string): Promise<void> {
    await this.router.navigate([`/profile/${id}`]);
  }
}
