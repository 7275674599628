import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {Gender, Genders, PetBreed, PetCategory, PetProfile} from "@pettly/models/petProfile";
import {Constants} from "@pettly/shared/constants";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BreedService} from "@pettly/services/breed/breed.service";
import {formatDate, NgForOf, NgIf} from "@angular/common";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {ImageFallbackDirective} from "@pettly/libraries/image-fallback.directive";

@Component({
    selector: 'edit-profile',
    templateUrl: './edit-profile.component.html',
    imports: [
        ReactiveFormsModule,
        NgIf,
        NgForOf
    ],
    styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  profile: PetProfile = new PetProfile();
  showWait = false;
  submitted = false;
  breeds: Array<PetBreed>;
  groups: Array<PetCategory>;
  public imageSrc = Constants.DEFAULT_PROFILE_PICTURE;
  private profilePictureContent: string;
  formGroup = this.formBuilder.group({
    name: ['', [Validators.minLength(2), Validators.required]],
    petGroup: ['', [Validators.required],],
    petBreed: ['', [Validators.required],],
    dateOfBirth: ['', Validators.required],
    gender: ['', [Validators.required, Validators.pattern("^[0129]{1,1}$")],],
    description: [''],
    // file: [null],
  });

  get controls(): { [p: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public get genders(): Gender[] {
    return Genders.all;
  }

  constructor(
    private petProfileService: ProfileService,
    private profileStore: PetProfileStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private breedManagementService: BreedService,
    private formBuilder: FormBuilder,
  ) {

  }

  async ngOnInit(): Promise<void> {
    try {
      this.initializeSelectedProfile();
      await this.initializeBreedInControl();
      await this.initializeForm();
    } catch (err) {
      alert(JSON.stringify(err))
    }
  }

  private initializeSelectedProfile() {
    this.profile = this.profileStore.activeProfile();

    if (!this.profile.profilePictureLocation) {
      this.profile.profilePictureLocation = Constants.DEFAULT_PROFILE_PICTURE;
    }
  }

  private async initializeBreedInControl(): Promise<void> {
    this.groups = await this.breedManagementService.getAllGroups();
    this.breeds = await this.breedManagementService.getAllBreeds(this.profile.breed.group.id);
  }

  private async initializeForm() {
    this.controls['name'].setValue(this.profile.name);
    this.controls['gender'].setValue(this.profile.gender);
    this.controls['description'].setValue(this.profile.description);
    this.controls['dateOfBirth'].setValue(formatDate(this.profile.dateOfBirth, 'yyyy-MM-dd', 'en'));
    this.controls['petGroup'].setValue(this.profile.breed.group.id, {onlySelf: true});
    this.controls['petBreed'].setValue(this.profile.breed.id, {onlySelf: true});
  }

  public async onSelect(): Promise<void> {
    this.breeds = await this.breedManagementService.getAllBreeds(this.controls.petGroup.value);
    this.controls["petBreed"].setValue("");
    this.controls["petBreed"].markAsTouched();
  }

  public async updateProfile(): Promise<void> {
    try {
      this.submitted = true;
      if (this.formGroup.pristine || this.formGroup.invalid) return;
      this.showWait = true;

      await this.petProfileService.savePetProfile(this.parseProfileFromControl());
      await this.onSaveComplete();
    } catch (error) {
      alert("failed to create error:" + JSON.stringify(error));
    } finally {
      this.showWait = false;
    }
  }

  async onSaveComplete(): Promise<void> {
    await this.router.navigate(["/profile"]);
  }

  readUrl(event): void {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (): void => {
      this.imageSrc = reader.result as string;
      this.profilePictureContent = (reader.result as string).split(",")[1];
    };
  }

  private parseProfileFromControl(): PetProfile {
    const profile = new PetProfile();
    profile.id = this.profile.id;
    profile.name = this.controls['name'].value;
    profile.gender = this.controls['gender'].value;
    profile.dateOfBirth = this.controls['dateOfBirth'].value;
    profile.description = this.controls['description'].value;
    profile.breedId = this.controls['petBreed'].value;
    if (this.profilePictureContent) {
      profile.profilePictureLocation = this.profilePictureContent;
    }
    return profile;
  }

}
