import {Component, OnInit, Signal} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";
import {Router} from "@angular/router";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import { AlertsComponent } from "./alerts/alerts.component";
import { TimelineComponent } from "../timeline/timeline.component";
import { UserDetailComponent } from "./userDetail/userDetail.component";

@Component({
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    standalone: true,
    imports: [
        UserDetailComponent,
        TimelineComponent,
        AlertsComponent,
    ],
})
export class HomeComponent {
  public get activeProfile(): Signal<PetProfile> {
    return this.profileStore.activeProfile;
  }

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  }

  constructor(
    private profileStore: PetProfileStorageService,
    private router: Router
  ) {
  }

}
