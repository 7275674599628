<div class="page">
  <div class="top">
    <div>
      <img
        class="profile-pic"
        src="{{selectedProfile.profilePictureLocation}} "
        alt="Profile image example"
        width="80"
        height="80"
      />
      <h1 class="mb-1">{{ selectedProfile.name }}</h1>
      <h3>{{ selectedProfile.breed.name }}</h3>
    </div>
    <div>
      <button class="btn btn-outline-primary" (click)="close.emit()">
        Switch Account
      </button>
    </div>
  </div>
  <div class="profile-options">
    <div class="item" (click)="showProfileDetails()">
      <button><img src="assets/icons/dog.svg" alt="view_profile"/></button>
      <h6 class="m-0">View Profile</h6>
    </div>
    <div class="item">
      <button><img src="assets/icons/add.svg" alt="add_pet"/></button>
      <h6 class="m-0">Add Pet</h6>
    </div>
    <div class="item">
      <button><img src="assets/icons/setting.svg" alt="setting"/></button>
      <h6 class="m-0">Settings</h6>
    </div>
    <div class="item" (click)="logoutClicked()">
      <button><img src="assets/icons/logout.svg" alt="logout"/></button>
      <h6 class="m-0">Logout</h6>
    </div>
  </div>

</div>
