<div class="profile-container">
  <div class="left">
    <app-container-box heading="About me">
      <app-profile-detail [activeProfile]="selectedProfile()"></app-profile-detail>
    </app-container-box>
    <app-container-box heading="Friends" [count]="254">
      <app-friend-list [activeProfile]="selectedProfile()"></app-friend-list>
    </app-container-box>
  </div>
  <div class="right">
    <app-activity-feed [profile]="selectedProfile()"></app-activity-feed>
  </div>
</div>
