import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PostResponse, PostService} from "@pettly/services/post/post.service";
import {Constants} from "@pettly/shared/constants";
import {PetProfile} from "@pettly/models/petProfile";
import {OEmbed, OEmbedService} from "@pettly/services/ui/oEmbed.service";
import {iframely} from "@iframely/embed.js";
import {PreviewFrom} from "./PreviewFrom";
import {PopupService} from "@pettly/components/core/popup/popup.service";
import {CountPipe} from "@pettly/libraries/count-pipe";
import {SafeHtmlPipe} from "@pettly/libraries/safeHtmlPipe";
import {RetrospectiveDateFormatPipe} from "@pettly/libraries/date-pipe";
import {DialogComponent} from "../../dialog/dialog.component";
import {CommentComponent} from "./comment/comment.component";
import {NgSwitch, NgSwitchCase, NgStyle, NgFor, NgIf, SlicePipe} from "@angular/common";
import {ImageFallbackDirective} from "@pettly/libraries/image-fallback.directive";
import {NgxLinkifyjsModule} from "ngx-linkifyjs-v2";

@Component({
    selector: "post-element",
    templateUrl: "./post.component.html",
    styleUrls: ["./post.component.scss"],
    imports: [ImageFallbackDirective, NgSwitch, NgSwitchCase, NgStyle, NgFor, NgIf, CommentComponent, DialogComponent, SlicePipe, NgxLinkifyjsModule, RetrospectiveDateFormatPipe, SafeHtmlPipe, CountPipe]
})
export class PostComponent implements OnInit {
  @Input() post: PostResponse;
  public mobileComment = false;
  @Input() activeProfile: PetProfile;
  @Output() menuClicked: EventEmitter<PostResponse> = new EventEmitter();

  public oEmbedContent: OEmbed;
  public hasMyReaction: boolean;
  public commentBoxVisible: boolean;
  public previewItem: PreviewFrom;
  public PreviewFromTypes = PreviewFrom;
  public fallBackProfilePicture = Constants.DEFAULT_PROFILE_PICTURE;

  constructor(
    private postService: PostService,
    private oEmbedService: OEmbedService,
    public popupService: PopupService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.updateOwnReactions();
    await this.setContentPreview();
    if (window.screen.width < 576) {
      this.mobileComment = true;
    }
  }

  async setContentPreview(): Promise<void> {
    if (this.post.mediaUris.length > 0) {
      this.previewItem = PreviewFrom.Image;
    } else if (this.post.linkPreview?.video) {
      this.previewItem = PreviewFrom.Video;
    } else if (this.post.linkPreview?.url) {
      await this.loadHyperLinkPreview();
      this.previewItem = PreviewFrom.Link;
    } else {
      this.previewItem = PreviewFrom.None;
    }
  }

  private async loadHyperLinkPreview(): Promise<void> {
    if (this.post.linkPreview.url) {
      this.oEmbedContent = await this.oEmbedService.htmlFor(
        this.post.linkPreview.url
      );
      this.post.linkPreview.video = "true";
      iframely.load();
    }
  }

  private updateOwnReactions(): void {
    this.post.reactions?.forEach((reaction) => {
      if (reaction.author.id === this.activeProfile.id) {
        this.hasMyReaction = true;
      }
    });
  }

  private loadMediaFromLinkPreviewIfNotPresent(): void {
    if (this.post.mediaUris?.length === 0 && this.post.linkPreview) {
      this.post.mediaUris.push(this.post.linkPreview.image);
    }
  }

  formatLink(value, type) {
    if (type === 'url' && value.length > 40) {
      value = value.slice(0, 40) + '…';
    }
    return value;
  }

  public async sendReaction(): Promise<void> {
    try {
      if (!this.hasMyReaction) {
        await this.addReaction();
      } else {
        await this.removeReaction();
      }
    } catch {
      alert("failed to add reaction");
    }
  }

  private async addReaction(): Promise<void> {
    const data = await this.postService.createReaction(
      0,
      this.post.id,
      this.activeProfile.id
    );
    this.post.reactions.push(data);
    this.hasMyReaction = true;
  }

  private async removeReaction(): Promise<void> {
    const reactionIndex = this.post.reactions.findIndex(
      (r) => r.author.id === this.activeProfile.id
    );
    const reaction = this.post.reactions[reactionIndex];
    await this.postService.removeReaction(
      reaction.id,
      this.post.id,
      this.activeProfile.id
    );
    this.post.reactions.splice(reactionIndex, 1);
    this.hasMyReaction = false;
  }

  public toggleCommentBox(): void {
    this.commentBoxVisible = !this.commentBoxVisible;
  }

}
