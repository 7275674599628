import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';

import { initApp, jwtTokenGetter } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfirmEmailComponent } from './app/components/account/confirm-email/confirm-email.component';
import { ResetPasswordConfirmationComponent } from '@pettly/components/account/resetpasswordconfirmation/resetpasswordconfirmation.component';
import { PrivacyPolicyComponent } from '@pettly/components/privacy-policy/privacy-policy.component';
import { ServerErrorComponent, NotFoundComponent } from '@pettly/components/errorPages/errorPages.component';
import { NotificationComponent } from '@pettly/components/navbar/notification/notification.component';
import { CreateProfileComponent } from '@pettly/components/createprofile/createprofile.component';
import { PetCatalogComponent } from '@pettly/components/catalog/petCatalog.component';
import { ProfileComponent } from '@pettly/components/profile/profile.component';
import { ResetPasswordComponent } from '@pettly/components/account/resetpassword/resetpassword.component';
import { ForgotPasswordComponent } from '@pettly/components/account/forgotpassword/forgotpassword.component';
import { RegistrationComponent } from '@pettly/components/account/registration/registration.component';
import { LoginComponent } from '@pettly/components/account/login/login.component';
import { AccountComponent } from '@pettly/components/account/account.component';
import { HomeComponent } from '@pettly/components/home/home.component';
import { withInMemoryScrolling, provideRouter } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { GlobalErrorHandler } from '@pettly/services/error/errorHandler.service';
import { AndroidNotch } from '@awesome-cordova-plugins/android-notch/ngx';
import { SessionRecoveryInterceptor } from '@pettly/services/interceptorV2';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PopupService } from '@pettly/components/core/popup/popup.service';
import { ApiRequestInfrastructure } from '@pettly/services/apiRequestInfrastructure';
import { NotificationService } from '@pettly/services/notification/notification.service';
import { OEmbedService } from '@pettly/services/ui/oEmbed.service';
import { LinkPreviewService } from '@pettly/services/ui/linkPreview.service';
import { SharedUiService } from '@pettly/services/ui/sharedUi.service';
import { PetCatalogService } from '@pettly/services/catalog/petCatalog.service';
import { BreedService } from '@pettly/services/breed/breed.service';
import { AuthStorageService } from '@pettly/services/auth/auth-storage.service';
import { ProfileService } from '@pettly/services/profile/petprofile.service';
import { PostService } from '@pettly/services/post/post.service';
import { AuthService } from '@pettly/services/auth/auth.service';
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {NgxLinkifyjsModule} from "ngx-linkifyjs-v2";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, InfiniteScrollModule, NgxSkeletonLoaderModule, PickerModule, NgxLinkifyjsModule.forRoot(), JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                allowedDomains: [
                    "localhost:62541",
                    "localhost:62540",
                    "localhost:8080",
                    "localhost:5000",
                    "api.pettly.in",
                    "pettlyapi.selwe.com",
                ],
            },
        }), ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        })),
        AuthService,
        PostService,
        ProfileService,
        BreedService,
        AuthStorageService,
        PetCatalogService,
        SharedUiService,
        LinkPreviewService,
        OEmbedService,
        NotificationService,
        ApiRequestInfrastructure,
        PopupService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionRecoveryInterceptor,
            multi: true,
        },
        provideAppInitializer(() => {
        const initializerFn = (initApp)(inject(AuthStorageService), inject(ProfileService));
        return initializerFn();
      }),
        { provide: Window, useValue: window },
        { provide: LOCALE_ID, useValue: 'en-US' },
        AndroidNotch,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
        provideRouter([
            { path: "home", redirectTo: "", pathMatch: "full" },
            { path: "", component: HomeComponent },
            {
                path: "",
                component: AccountComponent,
                data: { isHeaderVisible: false },
                children: [
                    { path: "login", component: LoginComponent },
                    { path: "register", component: RegistrationComponent },
                    { path: "forgot-password", component: ForgotPasswordComponent },
                    { path: "reset-password", component: ResetPasswordComponent },
                ],
            },
            { path: "profile/:id", component: ProfileComponent },
            { path: "profile", component: ProfileComponent },
            { path: "search", component: PetCatalogComponent },
            { path: "explore", component: PetCatalogComponent },
            { path: "createProfile", component: CreateProfileComponent, data: { isHeaderVisible: false } },
            { path: "notification", component: NotificationComponent },
            { path: "serverError", component: ServerErrorComponent, data: { isHeaderVisible: false } },
            { path: "privacy-policy", component: PrivacyPolicyComponent, data: { isHeaderVisible: false } },
            {
                path: "resetpasswordconfirmation",
                component: ResetPasswordConfirmationComponent,
                data: { isHeaderVisible: false }
            },
            {
                path: "confirm-email",
                component: ConfirmEmailComponent,
                data: { isHeaderVisible: false }
            },
            { path: "**", component: NotFoundComponent, data: { isHeaderVisible: false } },
        ], withInMemoryScrolling({ scrollPositionRestoration: "enabled" })),
    ]
})
  .catch(err => console.log(err));
