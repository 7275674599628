<!--Settings Page-->
<div *ngIf="!showProfile">
  <profile-settings [selectedProfile]="selectedProfile()" (close)="popup.showHidePopup()">
  </profile-settings>
</div>
<div class="page" *ngIf="showProfile">
  <profile-cover [selectedProfile]="selectedProfile()" [profiles]="profiles()"></profile-cover>
  <profile-container-mobile *ngIf="isMobileDevice" [selectedProfile]="selectedProfile()"></profile-container-mobile>
  <profile-container-desktop *ngIf="!isMobileDevice" [selectedProfile]="selectedProfile()"></profile-container-desktop>
</div>

<app-dialog class="profile-popup" title="Switch Profile" #popup>
  <profile-list-selector class="content" [selectedProfile]="selectedProfile()"
                         [profiles]="profiles()"></profile-list-selector>
  <button class="btn btn-primary profile-btn" type="button">Add New Account</button>
</app-dialog>
