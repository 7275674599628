import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {Constants} from "@pettly/shared/constants";

@Directive({
  selector: 'img[fallbackSrc]',
  standalone: true
})
export class ImageFallbackDirective {
  @Input() fallbackSrc: string;
  private element: HTMLImageElement;

  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement
  }

  @HostListener("error")
  private onError() {
    this.element.src = this.fallbackSrc ?? Constants.DEFAULT_PROFILE_PICTURE;
    this.element.alt = 'No Image';
  }
}
