import {
  Component,
  ElementRef,
  Input, OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";
import {PostResponse, PostService} from "@pettly/services/post/post.service";
import { PostComponent } from "../../timeline/post/post.component";
import { NgFor } from "@angular/common";
import { CreatePostComponent } from "../../timeline/createPost/createPost.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@Component({
    selector: "app-activity-feed",
    templateUrl: "./activityFeed.component.html",
    styleUrls: ["./activityFeed.component.scss"],
    standalone: true,
    imports: [
        InfiniteScrollModule,
        CreatePostComponent,
        NgFor,
        PostComponent,
    ],
})
export class ActivityFeedComponent implements OnChanges {
  @Input() profile: PetProfile;
  public posts: Array<PostResponse>;
  @ViewChild("timeline", {static: false}) scrollFrame: ElementRef;
  private lastPage = 0;
  public loading = true;

  constructor(private postService: PostService) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes["profile"]) {
      this.lastPage = 0;
      this.posts = [];
      await this.fetchNewPosts();
    }
  }

  public async onScrollDown(): Promise<void> {
    if (this.loading) return;
    await this.fetchNewPosts();
  }

  private async fetchNewPosts(): Promise<void> {
    if (this.profile.id) {
      this.lastPage++;
      const response = await this.postService.getActivityFeed(
        this.profile.id,
        this.lastPage,
        20
      );
      this.loading = false;
      this.posts = this.posts.concat(response);
      this.posts = this.posts.concat(ActivityFeedComponent.preFeed());
    }
  }

  postRemovedHandler(post: PostResponse): void {
    const index = this.posts.indexOf(post);
    this.posts.splice(index, 1);
  }

  private static preFeed(): PostResponse[] {
    return [
      {
        id: "0",
        mediaUris: [
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
        ],
        content: "",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [
          {
            id: "01",
            author: {
              id: "01",
              name: "Nancy Allen",
              profilePictureUri: "assets/images/Post/profile1.jpg",
            },
            createdAt: new Date("2021-07-11T09:23:13.470Z"),
            content: "Ohh she is so cute. 😍",
            reactions: [],
          },
        ],
      },
      {
        id: "1",
        content: "",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "3",
        mediaUris: [],
        content:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "11",
        mediaUris: [
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
        ],
        content:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "12",
        content:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut l",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "13",
        content: "Lorem ipsum dolor sit amet",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "14",
        content: "Lorem ipsum dolor sit amet",
        mediaUris: [],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
      {
        id: "15",
        content: "www.youtube.com",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: undefined,
        reactions: [],
        comments: [],
      },
    ];
  }
}
