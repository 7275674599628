import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from "@angular/common";

@Pipe({
    name: 'countPipe',
    standalone: true
})
export class CountPipe implements PipeTransform {
  public constructor(@Inject(LOCALE_ID) locale) {
  }

  transform(value: number, ...args: unknown[]): string {
    if (value >= 1000000)
      return formatNumber(value / 1000000, 'en-US', "1.0-1") + 'm';
    if (value >= 1000)
      return formatNumber(value / 1000, 'en-US', "1.0-1") + 'k';
    if (value == 0)
      return '';
    return value.toString();
  }
}
