import {Directive, ElementRef, Input, OnDestroy, Renderer2, ViewContainerRef} from '@angular/core';
import {TemplateRef} from "@angular/core";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {merge, Observable, Subscription} from "rxjs";
import {TemplatePortal} from "@angular/cdk/portal";

@Directive({
  selector: '[puiPopup]',
  host: {
    "(click)": "toggleDropdown()"
  },
  standalone: true
})

export class PopOverTargetDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input("puiPopup") public dropdownPanel: TemplateRef<any>;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      panelClass: 'popup',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 8
          }
        ])
    });

    const templatePortal = new TemplatePortal(
      this.dropdownPanel,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);
    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      () => this.destroyDropdown()
    );
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const outsideClick$ = this.overlayRef.outsidePointerEvents();
    return merge(backdropClick$, detachment$, outsideClick$);
  }

  private destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
