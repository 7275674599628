<div class="social-buttons">
  <button class="btn btn-social mt-4" (click)="googleLogin()">
    <div class="logo-box">
      <img ngSrc="assets/icons/google.png" alt="Google logo" width="20" height="20"/>
    </div>
    <span>Sign in with Google</span>
  </button>
  <button class="btn btn-social mt-4" (click)="fbLogin()">
    <div class="logo-box">
      <img ngSrc="assets/icons/facebook.png" alt="Facebook logo" width="20" height="20"/>
    </div>
    <span>Sign in with Facebook</span>
  </button>
</div>
