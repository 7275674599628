import {Component} from '@angular/core';
import { NgFor } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-post-skeleton',
    templateUrl: './post-skeleton.component.html',
    styleUrls: ['../../post.component.scss'],
    standalone: true,
    imports: [NgxSkeletonLoaderModule, NgFor]
})
export class PostSkeletonComponent {
  constructor() {
  }
}
