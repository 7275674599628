import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {PostResponse, PostService} from "@pettly/services/post/post.service";
import {PetProfile} from "@pettly/models/petProfile";
import {Subscription} from "rxjs";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {ViewportScroller, NgFor, NgIf} from "@angular/common";
import {environment} from "environments/environment";
import {PopupService} from "@pettly/components/core/popup/popup.service";
import {PostSkeletonComponent} from "./post/postSkeleton/post-skeleton/post-skeleton.component";
import {PostComponent} from "./post/post.component";
import {CreatePostComponent} from "./createPost/createPost.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

@Component({
  selector: "app-timeline-element",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
  standalone: true,
  imports: [
    InfiniteScrollModule,
    CreatePostComponent,
    NgFor,
    PostComponent,
    NgIf,
    PostSkeletonComponent,
  ],
})
export class TimelineComponent implements OnChanges, OnDestroy {
  public posts: Array<PostResponse>;
  @Input() public activeProfile:  PetProfile;
  private lastPage = 0;
  public loading = true;
  private _subscription: Subscription;
  @ViewChild('menu', {read: TemplateRef}) menuTemplate: TemplateRef<any>;

  constructor(private postService: PostService,
              private sharedUiService: SharedUiService,
              private viewPortScroller: ViewportScroller,
              public popupService: PopupService) {
    if (!environment.production)
      this.posts = TimelineComponent.preFeed();
    else
      this.posts = [];
    this._subscription = this.sharedUiService.refreshTimeLine.subscribe(() => {
      this.scrollTimeLineToTop();
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes["activeProfile"]) {
      this.lastPage = 0;
      if (!environment.production)
        this.posts = TimelineComponent.preFeed();
      else
        this.posts = [];
      await this.fetchNewPosts();
    }
  }

  public async onScrollDown(): Promise<void> {
    if (this.loading) return;
    await this.fetchNewPosts();
  }

  private async fetchNewPosts(): Promise<void> {
    if (this.activeProfile.id) {
      this.lastPage++;
      this.loading = true;
      const response = await this.postService.getFeed(
        this.activeProfile.id,
        this.lastPage,
        20
      );
      this.loading = false;
      this.posts = this.posts.concat(response);
    }
  }

  postCreatedHandler(post: PostResponse): void {
    this.posts.unshift(post);
  }

  menuClickedHandler(post: PostResponse): void {
    const ref = this.menuTemplate.createEmbeddedView({post: post});
    this.popupService.showAsElement(ref.rootNodes[0])
  }

  scrollTimeLineToTop() {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private static preFeed(): PostResponse[] {
    return [
      {
        id: "0",
        mediaUris: [
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
        ],
        content: "",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [
          {
            id: "01",
            author: {
              id: "01",
              name: "Nancy Allen",
              profilePictureUri: "assets/images/Post/profile1.jpg",
            },
            createdAt: new Date("2021-07-11T09:23:13.470Z"),
            content: "Paste_your_text_here_:)Lorem_ipsum_dolor_sit_amet,_consetetur_sadipscing_elitr,_sed_diam_nonumy_eirmod_tempor_invidunt_ut_labore_et_dolore_magna_aliquyam_erat,_sed_diam_voluptua._At_vero_eos_et_accusam_et_justo_duo_dolores_et_ea_rebum._Stet_clita_kasd_gubergren,_no_sea_takimata_sanctus_est_Lorem_ipsum_dolor_sit_amet._Lorem_ipsum_dolor_sit_amet,_consetetur_sadipscing_elitr,_sed_diam_nonumy_eirmod_tempor_invidunt_ut_labore_et_dolore_magna_aliquyam_eratx",
            reactions: [],
          },
          {
            id: "02",
            author: {
              id: "01",
              name: "Nancy Allen",
              profilePictureUri: "assets/images/Post/profile1.jpg",
            },
            createdAt: new Date("2021-07-11T09:23:13.470Z"),
            content: "Ohh she is  LLong and boring making eugly and there is no limit to conetent sizd so cute. 😍",
            reactions: [],
          },
          {
            id: "03",
            author: {
              id: "01",
              name: "Nancy Allen",
              profilePictureUri: "assets/images/Post/profile1.jpg",
            },
            createdAt: new Date("2021-07-11T09:23:13.470Z"),
            content: "Ohh she is so cute. LLong and boring making eugly and there is no limit to conetent sizd",
            reactions: [],
          },
        ],
      },
      {
        id: "1",
        content: "",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "3",
        mediaUris: [],
        content:
          "Paste_your_text_here_:)Lorem_ipsum_dolor_sit_amet,_consetetur_sadipscing_elitr,_sed_diam_nonumy_eirmod_tempor_invidunt_ut_labore_et_dolore_magna_aliquyam_erat,_sed_diam_voluptua._At_vero_eos_et_accusam_et_justo_duo_dolores_et_ea_rebum._Stet_clita_kasd_gubergren,_no_sea_takimata_sanctus_est_Lorem_ipsum_dolor_sit_amet._Lorem_ipsum_dolor_sit_amet,_consetetur_sadipscing_elitr,_sed_diam_nonumy_eirmod_tempor_invidunt_ut_labore_et_dolore_magna_aliquyam_eratx",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "11",
        mediaUris: [
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
          "assets/images/Post/image1.jpg",
          "assets/images/Post/image2.jpg",
          "assets/images/Post/image3.jpg",
        ],
        content:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "12",
        content:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut l",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "13",
        content: "Lorem ipsum dolor sit amet",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "14",
        content: "Lorem ipsum dolor sit amet",
        mediaUris: [],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
      {
        id: "15",
        content: "www.youtube.com",
        mediaUris: ["assets/images/Post/image4.jpg"],
        author: {
          id: "x",
          profilePictureUri: "assets/images/Post/profile1.jpg",
          name: "Nancy Allen",
        },
        createdAt: new Date(),
        updatedAt: "",
        linkPreview: null,
        reactions: [],
        comments: [],
      },
    ];
  }

  public async removePost(post: PostResponse): Promise<void> {
    try {
      if (post.author.id === this.activeProfile.id) {
        await this.postService.removePost(post.id, this.activeProfile.id);
      } else {
        await this.postService.hidePostFromTimeLine(
          post.id,
          this.activeProfile.id
        );
      }
      this.removeFromTimeline(post);
    } catch {
      alert("failed to remove post");
    }
  }


  private removeFromTimeline(post: PostResponse): void {
    const index = this.posts.indexOf(post);
    this.posts.splice(index, 1);
  }
}
