import {Inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly KEY = 'theme';
  private theme: WritableSignal<string> = signal('light');

  public get Theme(): Signal<string> {
    return this.theme;
  }

  constructor(@Inject(DOCUMENT) private document: Document
  ) {
  }

  public Initialize(): void {
    this.document.body.classList.remove('light');
    this.document.body.classList.remove('dark');
    const current = localStorage.getItem(this.KEY) ?? 'light';

    this.document.body.classList.toggle(current);
    this.theme.set(current);
  }

  public toggleTheme() {
    this.document.body.classList.toggle('light');
    this.document.body.classList.toggle('dark');
    const current = this.theme() === 'light' ? ('dark') : ('light');
    localStorage.setItem(this.KEY, current);
    this.theme.set(current);
  }
}
