import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd, ActivatedRoute, RouterLink } from "@angular/router";
import { combineLatest } from "rxjs";
import { NgStyle } from "@angular/common";
import { FormsModule } from "@angular/forms";
@Component({
    selector: "navbar-search-element",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    imports: [
        FormsModule,
        NgStyle,
        RouterLink,
    ]
})
export class NavbarSearchComponent implements OnInit {
  public query: string;
  public path: string;
  public searchParam: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url.replace("/", "");
      }
    });
  }

  ngOnInit(): void {
    this.query = "";
    this.path = this.router.url.replace("/", "");
    combineLatest([this.route.paramMap, this.route.queryParamMap]);
    this.route.queryParamMap.subscribe(async (param) => {
      this.searchParam = param.get("q");
    });
  }

  public async search(): Promise<void> {
    if (!this.query) return;
    await this.router.navigate(["search"], { queryParams: { q: this.query } });
  }
}
