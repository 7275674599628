import {Component, OnInit, Signal} from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {PetProfile, Gender, PetSearchProfile} from "@pettly/models/petProfile";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {Constants} from "@pettly/shared/constants";
import {combineLatest} from "rxjs";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import { GenderPipe } from "@pettly/libraries/gender-pipe";
import { NgIf, NgFor } from "@angular/common";

@Component({
    templateUrl: "./petCatalog.component.html",
    styleUrls: ["./petCatalog.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterLink,
        GenderPipe,
    ],
})
export class PetCatalogComponent implements OnInit {
  public get activeProfile(): Signal<PetProfile> {
    return this.petProfileSStorage.activeProfile;
  }

  public profiles: Array<PetSearchProfile>;
  public fallBackProfilePicture = Constants.DEFAULT_PROFILE_PICTURE;
  public searchParam: string;

  constructor(
    private petCatalogService: PetCatalogService,
    private petProfileSStorage: PetProfileStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    combineLatest([this.route.paramMap, this.route.queryParamMap]);

    this.route.queryParamMap.subscribe(async (param) => {
      this.searchParam = param.get("q");
      await this.disableEmptySearchOnLargeScreens();
      if (this.searchParam && this.searchParam.trim().length > 0) {
        this.profiles = await this.petCatalogService.searchPetProfilesFor(this.searchParam);
      } else {
        this.profiles = await this.petCatalogService.getRecommendedProfilesFor(this.activeProfile().id);
      }
    });
  }

  private async disableEmptySearchOnLargeScreens() {
    if (!this.searchParam && window.screen.width > 768) {
      await this.router.navigateByUrl("/");
    }
  }

  parseGender(value: number): Gender {
    return PetProfile.formattedGender(value);
  }

  async follow(publisherId: string): Promise<void> {
    await this.petCatalogService.subscribe(this.activeProfile().id, publisherId);
  }
}
