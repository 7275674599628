<ng-container *ngIf="visible">
  <div class="container" (clickedOutside)="showHidePopup()">
    <div class="header box-heading" [style.padding-top]="headerPadding">
      <i class="fas fa-chevron-left icon mob" (click)="showHidePopup()"></i>
      <i class="fas fa-times icon desk" (click)="showHidePopup()"></i>
      <span class="text-large">{{title}}</span>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
