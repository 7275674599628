import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Constants} from '@pettly/shared/constants';

@Pipe({
    name: 'retrospective',
    standalone: true
})
export class RetrospectiveDateFormatPipe extends DatePipe implements PipeTransform {
  override transform(value: Date, args?: any): any {
    const format = RetrospectiveDateFormatPipe.getFormat(value);
    let output = super.transform(value, format);
    if (format === Constants.DateFormatWithin24Hrs) {
      output += ' Today';
    }
    return output;
  }

  private static getFormat(value: Date): string {
    let format: string;
    const aDay = 1000 * 3600 * 24;
    const diff = Math.abs(Date.now().valueOf() - (new Date(value)).valueOf());
    const diffDays = Math.ceil(diff / aDay);

    if (diffDays > 365) {
      format = Constants.DateFormat;
    } else if (diffDays > 7) {
      format = Constants.DateFormatWithinSameYear;
    } else if (diffDays > 1) {
      format = Constants.DateFormatWithinSameWeek;
    } else {
      format = Constants.DateFormatWithin24Hrs;
    }
    return format;
  }
}
