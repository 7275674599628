<div class="profile-btn"
     [ngClass]="{active: selectedProfile  && selectedProfile.id === profile.id}" *ngFor="let profile of profiles">
  <div class="d-flex" (click)="switchProfile(profile)">
    <img [fallbackSrc]="fallBackProfilePicture" alt="profile-pic" class="rounded-circle"
         [src]="profile.profilePictureLocation"/>
    <div>
      <h6 class="text-large ">{{ profile.name }}</h6>
      <small>{{ profile.breed.name }}</small>
    </div>
  </div>
</div>
