<div class="card">
  <span class="icon-text">Create a new profile for the pet</span>
  <form class="form" (ngSubmit)="createProfile()" [formGroup]="createProfileForm">
    <div class="cover-image">
      <img class="cover-pic" src="assets/images/Profile/profile-cover.jpg" alt="Profile image"/>
      <img class="profile-pic" [src]="imageSrc" alt="Profile image"/>
    </div>
    <div class="caption center">
      <label for="profilePicture" class="btn btn-block center" role="button">
        Select your Picture
        <input type="file" id="profilePicture" accept="image/*" #profilePicture (change)="readUrl($event)"
               hidden/>
      </label>
    </div>
    <div>
      <input type="text" placeholder="Full name of pet" formControlName="petName">
      <div *ngIf="(controls.petName.touched||submitted) && controls.petName.errors"
           class="text-danger text-left">
        <small *ngIf="controls.petName.errors.required">Name is required.</small>
      </div>
    </div>
    <div class="form-group">
      <select (change)="onSelect()" formControlName="petGroup">
        <option selected disabled value="-1"> Select a Group</option>
        <option *ngFor="let group of groups" [value]="group?.id">
          {{ group.name }}
        </option>
      </select>
      <div *ngIf="(controls.petGroup.touched||submitted) && controls.petGroup.errors"
           class="text-danger text-left">
        <small *ngIf="controls.petGroup.errors">Pet group is required.</small>
      </div>
    </div>
    <div>
      <select formControlName="petBreed">
        <option selected disabled value="-1"> Select a breed</option>
        <option *ngFor="let breed of breeds" [value]="breed?.id">
          {{ breed.name }}
        </option>
      </select>
      <div *ngIf="(controls.petBreed.touched||submitted) && controls.petBreed.errors"
           class="text-danger text-left">
        <small *ngIf="controls.petBreed.errors">Pet breed is required.</small>
      </div>
    </div>
    <div>
      <input formControlName="dateOfBirth" type="date" placeholder="Date of birth">
      <div *ngIf="(controls.dateOfBirth.touched||submitted) && controls.dateOfBirth.errors"
           class="text-danger text-left">
        <small *ngIf="controls.dateOfBirth.errors.required">Date of birth is required.</small>
      </div>
    </div>
    <div>
      <div class="form-check-group">
        <label for="gender" class="from-inline control-left mr-sm-2">Gender: </label>
        <div class="form-check">
          <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=1
                 id="option1">
          <label class="form-check-label" for="option1">Male</label>
        </div>
        <div class="form-check">
          <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=2
                 id="option2">
          <label class="form-check-label" for="option2">Female</label>
        </div>
        <div class="form-check">
          <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=0
                 id="option3">
          <label class="form-check-label" for="option3">Not Known</label>
        </div>
        <div class="form-check">
          <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=9
                 id="option4">
          <label class="form-check-label" for="option4">Not Applicable</label>
        </div>
      </div>
      <div *ngIf="(controls.gender.touched||submitted) && controls.gender.errors"
           class="text-danger text-left">
        <small *ngIf="controls.gender.errors"> Gender is required. </small>
      </div>
    </div>


    <input formControlName="description" type="text" class="form-control"
           placeholder="Optional Description of your pet">

    <button type="submit" class="btn btn-azure">
      <i *ngIf='showWait' class='fa fa-paw fa-spin'></i>Let's Rock
    </button>
  </form>
</div>
