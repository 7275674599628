import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";
import {Notification, NotificationService, Pet,} from "@pettly/services/notification/notification.service";
import {Subscription} from "rxjs";
import { RetrospectiveDateFormatPipe } from "@pettly/libraries/date-pipe";
import { ImageFallbackDirective } from "../../../_libraries/image-fallback.directive";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "navbar-notification-element",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgxSkeletonLoaderModule,
        ImageFallbackDirective,
        RetrospectiveDateFormatPipe,
    ],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() activeProfile: PetProfile;
  public loading = true;
  public awaitingNotifications = 10;
  public notifications: Notification[] = [];
  allFeedSubscription: any;

  constructor(private notificationService: NotificationService) {
  }

  async ngOnInit(): Promise<void> {
    this.notifications = await this.notificationService.getNotifications(this.activeProfile.id, 1, 10);
    this.loading = false;
  }

  public commaSeparatedActorNames(pets: Pet[]): string {
    if (pets.length === 0) return "";
    let name = pets[0].name;
    if (pets.length === 1) return name;
    let i;
    for (i = 1; i < pets.length - 1; i++) {
      name += ", " + pets[i].name;
    }
    return name + " and " + pets[i].name;
  }

  //public newNotifications: {
  //     id: number;
  //     name: string;
  //     message: string;
  //     date: string;
  //     profile: string;
  //     media?: {
  //       id: number;
  //       type: string;
  //       url: string;
  //     }[];
  //   }[] = [];

  // private newNotifications1 = [
  //   {
  //     id: 0,
  //     name: "Nancy Allen",
  //     message: "added a new photo",
  //     date: "10:30 AM Today",
  //     profile: "assets/images/Profile/profile1.jpg",
  //     media: [
  //       {
  //         id: 0,
  //         type: "image",
  //         url: "assets/images/Post/image1.jpg",
  //       },
  //       {
  //         id: 1,
  //         type: "image",
  //         url: "assets/images/Post/image3.jpg",
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     name: "Aldrego",
  //     message: "added a new photo",
  //     date: "10:30 AM Today",
  //     profile: "assets/images/Profile/profile3.jpg",
  //     media: [
  //       {
  //         id: 0,
  //         type: "image",
  //         url: "assets/images/Post/image2.jpg",
  //       },
  //       {
  //         id: 1,
  //         type: "image",
  //         url: "assets/images/Post/image4.jpg",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Aldrego",
  //     message: "Started following you",
  //     date: "11:26 AM Today",
  //     profile: "assets/images/Profile/profile2.jpg",
  //   },
  // ];


  ngOnDestroy(): void {
    try {
      (<Subscription>this.allFeedSubscription).unsubscribe();
    } catch (error) {
      console.error(error);
    }
  }
}
