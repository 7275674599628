import {AuthStorageService} from "@pettly/services/auth/auth-storage.service";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import {Constants} from "@pettly/shared/constants";
import {Guid} from "./_models/Guid";

export function jwtTokenGetter(): string {
  return localStorage.getItem(Constants.TOKEN_KEY);
}

export function initApp(sharedDataService: AuthStorageService,
                        profileService: ProfileService) {
  return async (): Promise<void> => {
    if (!sharedDataService.getClientId()) {
      sharedDataService.setClientId(Guid.create());
    }
    if (sharedDataService.getAuthToken() && !await sharedDataService.isTokenExpired()) {
      await profileService.init();
    }
  };
}


