import {Component, OnInit, signal, Signal} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {PetProfile} from "@pettly/models/petProfile";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {ProfileListSelectorComponent} from "./profile-list-selector/profile-list-selector.component";
import {DialogComponent} from "../dialog/dialog.component";
import {ContainerBoxComponent} from "../container-box/container-box.component";
import {FriendListComponent} from "./friend-list/friend-list.component";
import {ActivityFeedComponent} from "./activity-feed/activityFeed.component";
import {ProfileDetailComponent} from "./profileDetail/profile-detail.component";
import {CoverComponent} from "./cover/cover.component";
import {SettingsComponent} from "./settings/settings.component";
import {NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {BreakpointObserver, BreakpointState, MediaMatcher} from "@angular/cdk/layout";
import {toSignal} from "@angular/core/rxjs-interop";
import {DesktopComponent} from "@pettly/components/profile/container/desktop/desktop.component";
import {MobileComponent} from "@pettly/components/profile/container/mobile/mobile.component";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    SettingsComponent,
    CoverComponent,
    ProfileDetailComponent,
    ActivityFeedComponent,
    FriendListComponent,
    ContainerBoxComponent,
    DialogComponent,
    ProfileListSelectorComponent,
    NgSwitch,
    NgSwitchCase,
    NgForOf,
    DesktopComponent,
    MobileComponent,
  ],
})
export class ProfileComponent implements OnInit {
  public showProfile = true;
  public selectedProfile: Signal<PetProfile>;

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  }

  private mobileBreakpoint: Signal<BreakpointState>;

  public get isMobileDevice() {
    return this.mobileBreakpoint().matches;
  }

  constructor(
    private profileStore: PetProfileStorageService,
    private petCatalogService: PetCatalogService,
    private route: ActivatedRoute,
    private sharedUiService: SharedUiService,
    breakpointObserver: BreakpointObserver
  ) {
    this.sharedUiService.toggleProfileSettings.subscribe(() => {
      this.showProfile = !this.showProfile;
    });
    this.mobileBreakpoint = toSignal(breakpointObserver.observe('(max-width: 768px)'));
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      const profile = await this.petCatalogService.getPetProfileById(id)
      this.selectedProfile = signal(profile);
    } else {
      this.selectedProfile = this.profileStore.activeProfile;
    }
  }
}
