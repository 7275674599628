import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ProfileService} from '@pettly/services/profile/petprofile.service';
import {BreedService} from '@pettly/services/breed/breed.service';
import {PetBreed, PetCategory, PetProfile} from '@pettly/models/petProfile';

import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Constants} from '@pettly/shared/constants';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-createprofile',
    templateUrl: './createprofile.component.html',
    styleUrls: ['./createprofile.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor]
})
export class CreateProfileComponent implements OnInit {
  showWait = false;
  submitted = false;
  breeds: Array<PetBreed>;
  groups: Array<PetCategory>;
  createProfileForm: UntypedFormGroup;
  public imageSrc = Constants.DEFAULT_PROFILE_PICTURE;
  private profilePictureContent: string;

  get controls() {
    return this.createProfileForm.controls;
  }

  constructor(
    private profileManagementService: ProfileService,
    private breedManagementService: BreedService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.createProfileForm = this.formBuilder.group({
        petName: ['', Validators.required],
        petGroup: ['', [Validators.required, Validators.pattern('^(?!-1$).*$')]],
        petBreed: ['', [Validators.required, Validators.pattern('^(?!-1$).*$')]],
        dateOfBirth: ['', Validators.required],
        gender: ['', [Validators.required, Validators.pattern('^[0129]{1,1}$')]],
        description: [''],
        file: [null]
      });

      this.groups = await this.breedManagementService.getAllGroups();
      this.controls.petGroup.setValue(-1, {onlySelf: true});
      this.controls.petBreed.setValue(-1, {onlySelf: true});
    } catch (ex) {
      alert('failed to fetch breeds');
    }
  }

  public async createProfile(): Promise<void> {
    try {
      this.submitted = true;
      if (this.createProfileForm.invalid) {
        return;
      }
      this.showWait = true;
      await this.profileManagementService.savePetProfile(this.parseProfileFromControl());
      this.onSaveComplete();
    } catch {
      alert('failed to create error');
    } finally {
      this.showWait = false;
    }
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.router.navigate(['/home']);
  }

  async onSelect(): Promise<void> {
    this.breeds = await this.breedManagementService.getAllBreeds(this.controls.petGroup.value);
    this.controls.petBreed.setValue(-1, {onlySelf: true});
  }

  readUrl(event): void {
    const files = event.target.files;
    if (files.length === 0) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (): void => {
      this.imageSrc = reader.result as string;
      this.profilePictureContent = (reader.result as string).split(',')[1];
    };
  }

  private parseProfileFromControl(): PetProfile {
    const profile = new PetProfile();
    profile.name = this.controls.petName.value;
    profile.breed = new PetBreed();
    profile.breed.id = this.controls.petBreed.value;
    profile.gender = this.controls.gender.value;
    profile.dateOfBirth = this.controls.dateOfBirth.value;
    profile.description = this.controls.description.value;
    profile.breedId = this.controls.petBreed.value;
    if (this.profilePictureContent) {
      profile.profilePictureLocation = this.profilePictureContent;
    }
    // "media": {},
    // "livesIn": "string",
    // "nickName": "string",
    // "description": "string"
    return profile;
  }
}
