import {Component, input, Signal} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {ImageFallbackDirective} from "@pettly/libraries/image-fallback.directive";
import {Constants} from "@pettly/shared/constants";
import {DialogComponent} from "@pettly/components/dialog/dialog.component";
import {EditProfileComponent} from "@pettly/components/profile/edit-profile/edit-profile.component";
import {SwitchProfileComponent} from "@pettly/components/profile/switch-profile/switch-profile.component";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {ActivatedRoute} from "@angular/router";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {
  ProfileListSelectorComponent
} from "@pettly/components/profile/profile-list-selector/profile-list-selector.component";
import {NgIf} from "@angular/common";

@Component({
    selector: 'profile-cover',
    imports: [
        ImageFallbackDirective,
        DialogComponent,
        EditProfileComponent,
        SwitchProfileComponent,
        ProfileListSelectorComponent,
        NgIf
    ],
    templateUrl: './cover.component.html',
    styleUrl: './cover.component.scss'
})
export class CoverComponent {
  selectedProfile = input.required<PetProfile>();
  profiles = input.required<PetProfile[]>();

  public get fallbackProfilePicture(): string {
    return Constants.DEFAULT_PROFILE_PICTURE;
  }

  public get isOwnProfile() {
    return this.selectedProfile().id === this.profileStore.activeProfile().id;
  }

  constructor(
    private profileStore: PetProfileStorageService,
  ) {
  }

}
