import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, Subject, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {AuthService} from "@pettly/services/auth/auth.service";
import {AuthStorageService as StoreService} from "@pettly/services/auth/auth-storage.service";
import {TokenResponse} from "@pettly/models/TokenResponse";

@Injectable()
export class SessionRecoveryInterceptor implements HttpInterceptor {

  private _refreshSubject: Subject<any> = new Subject<TokenResponse>();

  constructor(
    private readonly store: StoreService,
    private readonly sessionService: AuthService
  ) {
  }

  private handleTokenExpired(): void {
    if (this._refreshSubject.observed) return;
    this.sessionService.refreshAuthToken().then(value => {
      this.store.setToken(value);
      this._refreshSubject.next(value);
      this._refreshSubject.complete();
      this._refreshSubject = new Subject<TokenResponse>();
    });
  }

  private static checkTokenExpiryError(error: HttpErrorResponse): boolean {
    return (error instanceof HttpErrorResponse) && error.status && error.status === 401;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (SessionRecoveryInterceptor.isAuthenticationUrl(req)) return next.handle(req);
    return next.handle(req).pipe(
      catchError((error, caught) => {
        if (!SessionRecoveryInterceptor.checkTokenExpiryError(error)) return throwError(() => error);
        if (!this.store.getRefreshToken()) return throwError(() => error);
        this.handleTokenExpired();
        return this._refreshSubject.pipe(switchMap(() => next.handle(this.updateHeader(req))));
      })
    );
  }

  private static isAuthenticationUrl(req: HttpRequest<any>) {
    return req.url.endsWith("/logout")
      || req.url.endsWith("/token/refresh")
      || req.url.endsWith("/token");
  }

  updateHeader(req: HttpRequest<any>) {
    const authToken = this.store.getAuthToken();
    req = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${authToken}`),
    });
    return req;
  }
}
