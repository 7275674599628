import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-social-buttons',
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './social-buttons.component.html',
  styleUrl: './social-buttons.component.scss'
})
export class SocialButtonsComponent {
  @Input() googleLogin: () => Promise<void>;
  @Input() fbLogin: () => Promise<void>;
}
