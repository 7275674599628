import {Component, EventEmitter, Input, Output, Signal} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {Router} from "@angular/router";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {AuthService} from "@pettly/services/auth/auth.service";

@Component({
  selector: 'profile-settings',
  standalone: true,
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  public showProfile = true;
  @Input() selectedProfile: PetProfile;
  @Output() close: EventEmitter<void> = new EventEmitter();

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  }

  constructor(
    private profileStore: PetProfileStorageService,
    private router: Router,
    private sharedUiService: SharedUiService,
    private authService: AuthService
  ) {
    this.sharedUiService.toggleProfileSettings.subscribe(() => {
      this.showProfile = !this.showProfile;
    });
  }

  public showProfileDetails(): void {
    this.showProfile = true;
  }


  async logoutClicked(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate(["/login"]);
  }
}
