import {Component, computed, OnInit, Signal} from "@angular/core";
import {AuthService} from "@pettly/services/auth/auth.service";
import {Event, NavigationEnd, Router, RouterLink} from "@angular/router";
import {PetProfile} from "@pettly/models/petProfile";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import {ApiError} from "@pettly/services/error/apiError";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {Location, NgIf, TitleCasePipe} from "@angular/common";
import {DeviceInfoService} from "@pettly/services/ui/device-info.service";
import {SignalrService} from "@pettly/services/signalr.service";
import {NotificationService} from "@pettly/services/notification/notification.service";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {CreatePostComponent} from "../timeline/createPost/createPost.component";
import {DialogComponent} from "../dialog/dialog.component";
import {FriendRequestComponent} from "./friend-request/friend-request.component";
import {NotificationComponent} from "./notification/notification.component";
import {ProfileListSelectorComponent} from "../profile/profile-list-selector/profile-list-selector.component";
import {PopOverTargetDirective} from "../../_libraries/pop-over-target.directive";
import {NavbarSearchComponent} from "./search/search.component";
import {CreateProfileComponent} from "@pettly/components/createprofile/createprofile.component";
import {ThemeService} from "@pettly/services/theme.service";

@Component({
  selector: "header-element",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [NavbarSearchComponent, PopOverTargetDirective, RouterLink, ProfileListSelectorComponent, NotificationComponent, FriendRequestComponent, NgIf, DialogComponent, CreatePostComponent, TitleCasePipe, CreateProfileComponent]
})
export class HeaderComponent implements OnInit {
  public isProfileSettingsCollapsed = true;
  public title = "";

  public get theme() {
    return this.themeService.Theme();
  }

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  };

  public get activeProfile(): Signal<PetProfile> {
    return this.profileStore.activeProfile;
  };

  public navbarPadding = "20px";
  public newNotificationCount = computed(() =>
    this.notificationService.newNotifications().filter(n => n.target.id == this.activeProfile().id).length);
  public otherPetNotificationCount = computed(() =>
    this.notificationService.newNotifications().filter(n => n.target.id != this.activeProfile().id).length);

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private profileStore: PetProfileStorageService,
    public router: Router,
    private sharedUiService: SharedUiService,
    private _location: Location,
    private deviceInfo: DeviceInfoService,
    private signalrService: SignalrService,
    private notificationService: NotificationService,
    private themeService: ThemeService,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.title = event.url.replace("/", "");
      }
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.themeService.Initialize();
      await this.setCutoutPadding();
      await this.profileService.getPetProfiles();
      if (this.profileStore.petProfiles().length === 0) await this.router.navigate(["/createProfile"]);

      this.title = this.router.url.replace("/", "");
      await this.connectToNotificationHub();
    } catch (err) {
      if (err instanceof ApiError) {
        err.errors.forEach((error) => {
          if (error.code === "NoPetProfilesFoundForUser") {
            this.router.navigate(["/createProfile"]);
          } else {
            throw err;
          }
        });
      } else {
        throw err;
      }
    }
  }

  async logoutClicked(): Promise<void> {
    try {
      await this.authService.logout();
    } catch (e) {
      if (e !== "Not logged in") {
        throw e;
      }
    }
    await this.router.navigate(["/login"]);
  }

  public openProfileSettings(): void {
    this.sharedUiService.toggleProfileSettings.emit();
    this.isProfileSettingsCollapsed = !this.isProfileSettingsCollapsed;
  }

  public backClicked(): void {
    this._location.back();
  }

  public async homeClicked(): Promise<void> {
    if (this.router.url !== "/") {
      await this.router.navigate(["/"]);
    } else {
      this.sharedUiService.refreshTimeLine.next();
    }
  }

  private async setCutoutPadding() {
    if (!await this.deviceInfo.hasNotch()) return;
    this.navbarPadding = (await this.deviceInfo.getInsetTop() + 8).toString() + "px";
  }

  private async connectToNotificationHub() {
    await this.signalrService.startConnection();
  }

  public toggleLightMode() {
    this.themeService.toggleTheme();
  }
}
