import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { PostService, PostResponse } from '@pettly/services/post/post.service';
import { DraftPost } from '@pettly/models/draftPost';
import { LinkPreviewService } from '@pettly/services/ui/linkPreview.service';
import { OEmbedService } from '@pettly/services/ui/oEmbed.service';
import { PreviewFrom } from '../post/PreviewFrom';
import { SafeHtmlPipe } from '@pettly/libraries/safeHtmlPipe';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'createPost-element',
    templateUrl: './createPost.component.html',
    styleUrls: ['./createPost.component.scss'],
    imports: [FormsModule, NgIf, NgSwitch, NgSwitchCase, SafeHtmlPipe]
})

export class CreatePostComponent {
  showWait = false;
  @Input() profileId: string;
  @Output() postCreated: EventEmitter<PostResponse> = new EventEmitter();
  @ViewChild('fileInput', { static: true }) fileInput;
  public PreviewFromTypes = PreviewFrom;
  public draftPost: DraftPost;


  constructor(
    private postService: PostService,
    linkPreviewService: LinkPreviewService,
    oEmbedService: OEmbedService) {
    this.draftPost = new DraftPost(linkPreviewService, oEmbedService);
  }

  public async sendPost(): Promise<void> {
    try {
      if (!this.draftPost.isPostable()) return;
      this.showWait = true;
      const createdPost = await this.postService.createPost(this.draftPost, this.profileId);
      this.draftPost.clear();
      this.postCreated.emit(createdPost);
    } catch {
      alert('failed to create post');
    }
    finally {
      this.showWait = false;
    }
  }

  readUrl(event): void {
    const files = event.target.files;
    if (files.length === 0) { return; }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (): void => {
      this.draftPost.updateMedia(reader.result);
    };
  }

}
