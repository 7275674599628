import {Component, Input, OnInit} from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-container-box",
    templateUrl: "./container-box.component.html",
    styleUrls: ["./container-box.component.scss"],
    standalone: true,
    imports: [NgIf],
})
export class ContainerBoxComponent {
  @Input() heading: string;
  @Input() count: number;

  constructor() {
  }
}
